import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../core/Auth";
import { login } from "../core/_requests";
import { useNavigate, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import CompanyLogo from "../../../img/di_solution.png";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { saveAuth } = useAuth();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .max(255, 'Maximum 255 characters only')
        .required("Email is required"),
    }),

    onSubmit: async (values) => {
      setLoading(true)
      try {
        const response = await login(values.email);
        if (response.data && response.data.success) {
          saveAuth(response.data);
          formik.resetForm();
          navigate("/");
          toast.success(response.data.message, { theme: "colored" });
        } else {
          toast.error(response.data.message, { theme: "colored" });
        }
        setLoading(false)
      } catch (error) {
        let { data } = error;
        if (data.message) {
          toast.error(data.message, { theme: "colored" });
        } else {
          toast.error(data.message, { theme: "colored" });
        }
        setLoading(false)
        saveAuth(undefined);
      }
    },
  });

  return (
    <div className="login">
      <div className="container">
        <div className="d-flex flex-column c-width-login">
        <div className="text-center">
          <img src={CompanyLogo} alt="" className="logoHeight"/>
        </div>
        <section className="cardWrapper">
          <div className="heading">
            <h1 className="text text-large">Sign In</h1>
            <p className="text text-normal">
              New user? <NavLink to="/auth/register">Create an account</NavLink>
            </p>
          </div>
          <form onSubmit={formik.handleSubmit} className="form">
            <div className="input-control">
              <input
                id="email"
                type="text"
                className=" input-field"
                autoComplete="off"
                placeholder="Enter your email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email && (
                <div style={{ color: "red", marginTop: "2px", marginLeft: "12px" }}>
                  {formik.errors.email}
                </div>
              )}
            </div>
            <div className="d-grid">
              <button type="submit" className="input-submit" disabled={loading ? true : false}>
                { loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Login" }
              </button>
            </div>
          </form>
        </section>
        </div>
      </div>
    </div>
  );
};

export default Login;

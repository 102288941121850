import React, { useEffect, useState } from "react";
import { getQuestions, saveUserAns } from "../../services/api";
import MainLogo from "../../img/logo.png";
import { useAuth } from "../auth/core/Auth";
import { toast } from "react-toastify";

const Home = () => {
  const [questions, setQuestions] = useState([]);
  const [queCounter, setQueCounter] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isExamOver, setIsExamOver] = useState(false);

  const activeBtn = {
    background: "#000",
    color: "#fff",
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let data = await getQuestions();
      if (data.data?.length > 0 && data.success) {
        setIsLoading(false);
        setQuestions(data.data);

        let startIndex = null;
        for (let i = 0; i < data.data.length; i++) {
          if (data.data[i].user_ans == null) {
            startIndex = i;
            break;
          }
        }
        if (startIndex == null) {
          setIsExamOver(true);
          setQuestions([]);
          return
        }
        setQueCounter(startIndex);
      }
    })();
  }, []);

  const handleNextQuestion = async (queId) => {
    if (selectedAnswer != null) {
      setIsLoading(true);
      const response = await saveUserAns(queId, selectedAnswer);
      if (response.success) {
        setIsLoading(false);
      }
      setSelectedAnswer(null);
    }
    setQueCounter((prev) => prev + 1);
  };

  const handleSubmit = async (queId) => {
    setIsLoading(true);
    const response = await saveUserAns(queId, selectedAnswer);
    if (response.success) {
      setIsLoading(false);
      setIsExamOver(true);
    }
  };

  return (
    <>
      <div className="mainContent">
        <div className="container-md text-center">
          {isLoading ? (
            <Loader />
          ) : (
            questions?.map((question, index, currArr) => {
              if (index === queCounter) {
                return (
                  <div
                    className="row question-card"
                    key={question.tbl_questions_id}
                  >
                    <div className="col-md-12 mb-3">
                      <h1>
                        {index + 1}. {question.questions}
                      </h1>
                    </div>
                    <div className="col-md-6 mb-3">
                      <button
                        type="button"
                        className="btn w-100 option-btn btn-16"
                        onClick={() => setSelectedAnswer("A")}
                        style={selectedAnswer === "A" ? activeBtn : null}
                      >
                        A. {question.A}
                      </button>
                    </div>
                    <div className="col-md-6 mb-3">
                      <button
                        type="button"
                        className="btn w-100 option-btn btn-16"
                        onClick={() => setSelectedAnswer("B")}
                        style={selectedAnswer === "B" ? activeBtn : null}
                      >
                        B. {question.B}
                      </button>
                    </div>
                    <div className="col-md-6 mb-3">
                      <button
                        type="button"
                        className="btn w-100 option-btn btn-16"
                        onClick={() => setSelectedAnswer("C")}
                        style={selectedAnswer === "C" ? activeBtn : null}
                      >
                        C. {question.C}
                      </button>
                    </div>
                    <div className="col-md-6 mb-3">
                      <button
                        type="button"
                        className="btn w-100 option-btn btn-16"
                        onClick={() => setSelectedAnswer("D")}
                        style={selectedAnswer === "D" ? activeBtn : null}
                      >
                        D. {question.D}
                      </button>
                    </div>
                    <div className="col-md-12 col-12 mb-3 text-end mt-3">
                      <button
                        disabled={selectedAnswer == null ? true : false}
                        type="button"
                        className="btn btn-dark p-3 ps-5 pe-5 next-btn"
                        onClick={() => {
                          if (queCounter >= currArr.length - 1) {
                            handleSubmit(question.tbl_questions_id);
                          } else {
                            handleNextQuestion(question.tbl_questions_id);
                          }
                        }}
                      >
                        {queCounter >= currArr.length - 1
                          ? "Submit"
                          : "Next Question"}
                      </button>
                    </div>
                  </div>
                );
              }
            })
          )}
          {isExamOver ? <Modal /> : null}
        </div>
      </div>
    </>
  );
};

export default Home;

const Modal = () => {
  const {logout} = useAuth();
  const handleLogout = () => {
    logout();
    toast.success("Logout Successful", { theme: "colored" });
  };
  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div
            className="modal-content p-3 position-relative"
            style={{ height: "fit-content" }}
          >
            <img src={MainLogo} alt="" className="modal-img" />
            <div className="thankyou-text">
              <h2 className="mb-3">Your exam is Over.</h2>
              <h3> Thanks for your valuable time.</h3>
              <button
                className="btn btn-outline-dark mt-4"
                onClick={handleLogout}
                type="button"
              >
                Signout
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

const Loader = () => {
  return (
    <div
      className="spinner-border"
      role="status"
      style={{ width: "60px", height: "60px" }}
    ></div>
  );
};

import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./components/Login";
import Registration from "./components/Registration";

const AuthPage = () => (
  <Routes>
    <Route path="login" element={<Login />} />
    <Route path="register" element={<Registration />} />
    <Route path="/*" element={<Navigate to="/login" />}/>
    <Route index element={<Login />} />
  </Routes>
);

export { AuthPage };

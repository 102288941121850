import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const LOGIN_URL = `${API_URL}/user/login`;
export const REGISTER_URL = `${API_URL}/user/SaveUser`;

export function login(email, password = "test") {
  return axios.get(LOGIN_URL, { params: { email: email, password: password}});
}

export function register(first_name, last_name, email, phone) {
  return axios.post(REGISTER_URL, {
    tbl_user_id: "",
    first_name,
    last_name,
    email,
    phone,
  });
}

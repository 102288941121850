import React, { useEffect, useState } from 'react'
import { NavLink} from 'react-router-dom'
import MainLogo from '../img/logo.png'
import Swal from 'sweetalert2'
import { useAuth } from '../modules/auth/core/Auth'
import { toast } from 'react-toastify'

const Navbar = () => {
    const {logout} = useAuth();
    const [timeLeft, setTimeLeft] = useState(() => {
        const savedTime = sessionStorage.getItem('timeLeft');
        return savedTime ? parseInt(savedTime) : 2400;
    });

    const sessionTimeout = () => {
        Swal.fire({
            title: "Time's up",
            icon: 'info',
            confirmButtonText: 'ok',
        }).then((result) => {
            if (result.isConfirmed) {
                sessionStorage.clear()
                 logout()
                 toast.success("Logout Successful", { theme: "colored" });
            }
        });
    };

    useEffect(() => {
        if (timeLeft <=0) {
            sessionTimeout(); // Call sessionTimeout when time runs out
            return;
        }
        sessionStorage.setItem('timeLeft', timeLeft)

        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                const newTime = prevTime - 1;
                sessionStorage.setItem('timeLeft', newTime);
                return newTime;
            });
        }, 1000); 

        // Cleanup the timer when the component unmounts or timeLeft changes
        return () => clearInterval(timer);
    }, [timeLeft, logout]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };


    const handleLogout = () => {
        Swal.fire({
            title: "Are you sure you want to Sign out?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sign out',
        }).then(async (result) => {
            if (result.isConfirmed) {
                logout();
                toast.success("Logout Successful", { theme: "colored" });
            }
        })
    }

  return (
    <>
        <nav className="navbar navbar-expand-lg ">
            <div className="container">
                <NavLink className="navbar-brand" to="/home"><img src={MainLogo}/></NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/home">Home</NavLink>
                    </li>
                </ul>
                <div className="d-flex align-items-center">
                            <div className="me-3">
                                <span>Time Left: {formatTime(timeLeft)}</span>
                            </div>
                            <button
                                className="btn btn-outline-dark"
                                onClick={handleLogout}
                                type="button"
                            >
                          Signout
                            </button>
                        </div>
                </div>
            </div>
        </nav>
    </>
  )
}

export default Navbar
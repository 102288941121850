import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { register } from "../core/_requests";
import { useAuth } from "../core/Auth";
import { useNavigate, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import CompanyLogo from "../../../img/di_solution.png";

const PHONE_REGEX = /^[1-9][0-9]{9}$/;
const NAME_REGEX = /^[A-Za-z]+$/;

const Registration = () => {
  const [loading, setLoading] = useState(false);
  const { saveAuth } = useAuth();
  const navigate = useNavigate();

  const errorStyle = { 
    color: "red", 
    marginTop: "2px",
    marginLeft: "12px"
  }

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },

    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(NAME_REGEX, "Only alphabets are allowed")
        .min(2, "Minimum 2 characters")
        .max(15, "Maximum 15 characters")
        .required("First name is required"),
      lastName: Yup.string()
        .matches(NAME_REGEX, "Only alphabets are allowed")
        .min(3, "Minimum 3 characters")
        .max(15, "Maximum 15 characters")
        .required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .max(255, "Maximum 255 characters")
        .required("Email is required"),
      phone: Yup.string()
        .matches(PHONE_REGEX, "Invalid phone number")
        .required("Phone no. is required"),
    }),

    onSubmit: async (values) => {
      console.log("Values:", values);
      setLoading(true);
      try {
        const response = await register(
          values.firstName,
          values.lastName,
          values.email,
          values.phone
        );
        if (response.data.data && response.data.success) {
          saveAuth(response.data.data);
          formik.resetForm();
          navigate("/");
          toast.success(response.data.message, { theme: "colored" });
        } else {
          toast.error(response.data.message, { theme: "colored" });
        }
        setLoading(false);
      } catch (error) {
        let { data } = error;
        if (data.message) {
          toast.error(data.message, { theme: "colored" });
        } else {
          toast.error(data.message, { theme: "colored" });
        }
        setLoading(false);
        saveAuth(undefined);
      }
    },
  });

  return (
    <div className="login">
      <div className="container">
        <div className="d-flex flex-column c-width-login">
          <div className="text-center">
            <img src={CompanyLogo} className="logoHeight" />
          </div>
          <section className="cardWrapper">
            <div className="heading">
              <h1 className="text text-large">Register</h1>
              <p className="text text-normal">
                Already have an account ?{" "}
                <NavLink to="/auth/login">Login</NavLink>
              </p>
            </div>
            <form onSubmit={formik.handleSubmit} className="form">
              <div className="mb-3">
                <input
                  name="firstName"
                  type="text"
                  className=" input-field"
                  id="firstName"
                  autoComplete="off"
                  placeholder="Enter first name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div style={errorStyle}>
                    {formik.errors.firstName}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <input
                  type="text"
                  name="lastName"
                  className=" input-field"
                  id="lastName"
                  autoComplete="off"
                  placeholder="Enter last name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div style={errorStyle}>
                    {formik.errors.lastName}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <input
                  id="email"
                  type="text"
                  className=" input-field"
                  autoComplete="off"
                  placeholder="Enter your email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <div style={errorStyle}>
                    {formik.errors.email}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <input
                  id="phone"
                  type="text"
                  className=" input-field"
                  autoComplete="off"
                  placeholder="Enter your phone no."
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div style={errorStyle}>
                    {formik.errors.phone}
                  </div>
                )}
              </div>

              <div className="d-grid">
                <button
                  type="submit"
                  className="input-submit"
                  disabled={loading ? true : false}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Register"
                  )}
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Registration;

import React from "react";
import axios from "axios";
import ReactDOM from "react-dom/client";
import "./index.css";
import { AuthProvider } from "./modules/auth/core/Auth";
import { setupAxios } from "./modules/auth/core/AuthHelper";
import AppRoutes from "./routing/AppRoutes";

setupAxios(axios)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <AppRoutes />
  </AuthProvider>
);

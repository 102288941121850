import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { useAuth } from "../modules/auth/core/Auth";
import { AuthPage } from "../modules/auth/AuthPage";
import App from "../App";
import Logout from "../modules/auth/Logout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoutes from "./PrivateRoutes";

const AppRoutes = () => {
  const { auth } = useAuth();

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route element={<App />}>
            <Route path="logout" element={<Logout />} />
            {auth?.token ? (
              <>
                <Route path="/*" element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/home' />} />
              </>
            ) : (
              <>
                <Route path="auth/*" element={<AuthPage />} />
                <Route path="*" element={<Navigate to="/auth" />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRoutes;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./core/Auth";

const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  
  useEffect(() => {
    logout();
    navigate('/')
    window.location.reload()
  }, []);

  return null;
}

export default Logout;

import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "../Layout/Navbar";
import Home from "../modules/main/Home";

const PrivateRoutes = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="home" element={<Home />} />
        <Route path="auth/*" element={<Navigate to="/home" />} />
        <Route index element={<Navigate to="/home" />} />
        <Route path="/*" element={<Navigate to="/home" />} />
      </Routes>
    </>
  );
};

export default PrivateRoutes;

import axios from "axios";

const API_URL_DATA = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { response } = error;
    console.log("response error", response);
    if (response.status === 401 && response.statusText === "Unauthorized") {
      localStorage.removeItem("qz-auth-react");
      window.location.reload();
    }
    return Promise.reject(response);
  }
);

export const getQuestions = async () => {
  return axios
    .get(`${API_URL_DATA}/questions/getQuestions`)
    .then((response) => {
      return response.data;
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
};

export const saveUserAns = async (tbl_questions_id, user_ans) => {
  return axios
    .post(`${API_URL_DATA}/questions/SaveUserAns`, {
      tbl_questions_id,
      user_ans,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
};
